import React, { useEffect, useMemo, useState } from "react";
import { isArray } from "lodash";
import cc from "classcat";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import { CloudDone, CloudUpload, Close } from "@material-ui/icons"
import { FormikFieldProps } from "../app-types";
import { useCustomizations } from "@personicom/customizations";
// import { GetPathFunc } from "@personicom/customizations/lib/esm/customization-types";
import { getFileUrl, hasHandlebars } from "features/app/app-helpers";

const buildStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    border: `1px dashed ${theme.palette.primary.main}`, //`
    borderRadius: 3,
  },
  errorContainer: {
    background: `${theme.palette.error.light}33`, //`
    border: `1px dashed ${theme.palette.error.light}`, //`
  },
  label: {
    width: "100%",
  },
  input: {
    display: "none",
  },
  imageLabel: {
    fontSize: 16,
    color: "#47505e",
  },
  actionButton: {
    marginRight: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    fontSize: 15,    
  },
}));

const FormikFileField : React.FC<FormikFieldProps> = ({formikProps, fieldProps, disabled, options, allInputs}) => {
  const classes = buildStyles();
  const { blobUrl } = useCustomizations();
  const isTemplated = useMemo(() => hasHandlebars(fieldProps.default), [fieldProps]);
  //TODO: modify this to mimic the defaulting logic from the formik-image-field component?
  const [selected, setSelected] = useState<string>(isArray(options) ? options[0] : fieldProps.default ?? options ?? "");
  const [fileUrl, setFileUrl]   = useState<string>(getFileUrl(selected, blobUrl));
  const [fileName, setFileName] = useState<string>("");
  const [isTouched, setWasTouched] = useState(false);

  useEffect(() => {
    if(!isTouched && (formikProps.isSubmitting || formikProps.touched[fieldProps.fieldId])){
      setWasTouched(true);
    }
  }, [isTouched, fieldProps.fieldId, formikProps.isSubmitting, formikProps.touched]);

  const errorMessage = useMemo(() => { return isTouched ? formikProps.errors[fieldProps.fieldId] || "" : ""; }, [isTouched, formikProps.errors, fieldProps.fieldId]);

  useEffect(() => {
    const formValue = formikProps.values[fieldProps.fieldId];
    if(isTemplated && formValue && formValue !== fileUrl){
      setFileUrl(formValue);
    }
  }, [formikProps, fieldProps, fileUrl, isTemplated]);

  const onChange  = (e : React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if(!files) return;

    const fileObj   = files[0];
    const fileUrl   = getFileUrl(fileObj, blobUrl);

    formikProps.setFieldValue(fieldProps.fieldId, fileObj);
    setFileUrl(fileUrl ?? "");
    setFileName(fileObj ? fileObj.name : "");
  }

  const onClear = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    formikProps.setFieldValue(fieldProps.fieldId, undefined);
    setFileUrl("");
    setSelected("");
  }

  return (
    <Grid container className={cc({[classes.container]: true, [classes.errorContainer]: !!errorMessage})}>
      <input accept={fieldProps.fileTypes ?? "*/*"} className={classes.input} id={fieldProps.fieldId} type="file" onChange={onChange} disabled={fieldProps.isReadOnly || disabled}/>
      <label htmlFor={fieldProps.fieldId} className={classes.label}>
        <Grid container alignItems="center">
          <Grid item sm={10} container alignItems="center">
            <Button component="span" startIcon={fileUrl ? <CloudDone /> : <CloudUpload />} variant="outlined" disabled={disabled} title={`Click to choose the ${fieldProps.fieldLabel}`} color="primary" size="small" aria-label="upload file" className={classes.actionButton}>{fileUrl ? "Change File" : "Choose File"}</Button>
            {!fileUrl && <Typography color="primary" className={classes.imageLabel}>{fieldProps.fieldLabel}{fieldProps.isRequired ? "*" : ""}</Typography>}
            {fileUrl && <Typography color="primary" className={classes.imageLabel}>{fileName}</Typography>}
          </Grid>
          {fileUrl && !fieldProps.isRequired &&
            <Grid item sm={2} container justify="flex-end">
              <IconButton component="span" size="small" disabled={disabled} title="Remove" color="primary" aria-label="remove picture" className={classes.actionButton} onClick={onClear}>
                <Close />
              </IconButton>
            </Grid>
          } 
          <Typography className={classes.errorText}>{errorMessage}</Typography>
        </Grid>      
      </label>
    </Grid>
  )
}

export default FormikFileField;