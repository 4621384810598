import { FormikFieldProps } from "../app-types";
import FormikTextField from "./formik-text-field";
import FormikImageField from "./formik-image-field";
import FormikImageSelectField from "./formik-image-select-field";
import FormikFileField from "./formik-file-field";
import FormikSelectField from "./formik-select-field";
import RecaptchaField from "./recaptcha-field";
import { isArray } from "lodash";
import { isConditionMet } from "features/app/app-helpers";

const FormikField: React.FC<FormikFieldProps> = ({ formikProps, fieldProps, disabled, options, allInputs, unusedValues }) => {
  //Check to see if this field should be hidden when there's a default value
  if (fieldProps.hideField || (fieldProps.hideIfInjected && options && !isArray(options))) {
    return null;  //don't show this field
  }

  //Check condition, don't render the control if the condition isn't met
  if (!isConditionMet(fieldProps, formikProps.values)) return null;

  switch (fieldProps.fieldType) {
    case "string":
    case "json":
    case "email":
    case "phone":
    case "url":
      return <FormikTextField formikProps={formikProps} fieldProps={fieldProps} disabled={disabled} options={options} allInputs={allInputs} />;

    case "image":
      return <FormikImageField formikProps={formikProps} fieldProps={fieldProps} disabled={disabled} options={options} allInputs={allInputs} />;

    case "image-select":
      return <FormikImageSelectField formikProps={formikProps} fieldProps={fieldProps} disabled={disabled} options={options} allInputs={allInputs} />;
      
    case "file":
      return <FormikFileField formikProps={formikProps} fieldProps={fieldProps} disabled={disabled} options={options} allInputs={allInputs} />;

    case "select":
      return <FormikSelectField formikProps={formikProps} fieldProps={fieldProps} disabled={disabled} options={options} allInputs={allInputs} />;

    case "captcha":
      return <RecaptchaField formikProps={formikProps} fieldProps={fieldProps} disabled={disabled} options={options} allInputs={allInputs} />;
  }

  return null;
}

export default FormikField;