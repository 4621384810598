import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { ActionItemProps } from "app-types";

const buildStyles = makeStyles((theme: any) => ({
  actionButton: {
    // marginTop: theme.spacing(2),
    ...theme.styles.actionButton,
  },
  
}));

const ActionButton : React.FC<ActionItemProps> = ({actionProps, onClick, isValid, isWorking}) => {
  const classes = buildStyles();

  return (
    <Button 
      onClick={onClick} 
      disabled={isWorking} 
      variant="contained" 
      // color="primary" 
      size={actionProps.size ?? "large"} 
      className={classes.actionButton}
      {...actionProps.props}
    >
      {actionProps.label}
    </Button>
  )
}

export default ActionButton;