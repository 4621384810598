import { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormikFieldProps } from "app-types";
import ReCAPTCHA from "react-google-recaptcha";
import cc from "classcat";

const buildStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  error: {
    // "& div": {
    //   border: `1px solid ${theme.palette.error.light}`, //`,
    //   "& div": {
    //     border: "unset",
    //   }
    // }
  }
}));

const RecaptchaField : React.FC<FormikFieldProps> = ({formikProps, fieldProps}) => {
  const classes = buildStyles();
  const props = useMemo(() => fieldProps.props ?? {}, [fieldProps]);
  const hasError = useMemo(() => formikProps.touched[fieldProps.fieldId] && Boolean(formikProps.errors[fieldProps.fieldId]), [formikProps, fieldProps.fieldId]);
  
  //HACK: centralize this in customizations or something. probably in a config.
  var CAPTCHA_KEY = "6Lf8U9YbAAAAAN4-UwvIpiFcB7BxaAWChSrXkp8k";
  const isLocalHost = Boolean(window.location.hostname.indexOf("localhost") >= 0);
  if(isLocalHost){
    CAPTCHA_KEY = "6LfaiNkbAAAAALI688SQEHEZdKaJGBddVmU-QFeY";
  }

  function onRecaptchaChange(value: any){
    console.log("Captcha value:", value);
    formikProps.setFieldValue(fieldProps.fieldId, value);
  }

  function onExpired(){
    formikProps.setFieldValue(fieldProps.fieldId, null);
  }

  return (
    <div className={cc({[classes.container]: true, [classes.error]: hasError})}>
      <ReCAPTCHA sitekey={CAPTCHA_KEY} onChange={onRecaptchaChange} onExpired={onExpired} {...props}/>
      {hasError && <p className="MuiFormHelperText-root Mui-error Mui-required MuiFormHelperText-marginDense">Captcha is required</p>}      
    </div>
  );
}

export default RecaptchaField;