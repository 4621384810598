import { CSSProperties, useEffect, useMemo, useState, useRef } from "react";
import { keys } from "lodash";
import { Button, Grid, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, FormikProps } from "formik";
import { LoadingIndicator } from "@personicom/customizations";
import { useCustomizations } from "@personicom/customizations";
import { FormikField, ActionButton, SuccessCard, ErrorCard } from "components";
import { createSchema, getSearchParam,  createInitialValues, getQueryStringValues, validateForm, DEFAULT_ACTION, compileValues } from "./app-helpers";
import { AppActionProps, AppFieldProps, AppFormProps } from "app-types";
import { doFetchWithToken, doFetch, getBaseUrl } from "helpers/api-helpers";
import AppFormWatcher from "./app-form-watcher";
import AppWaitContainer from "./app-wait-container";
declare var Handlebars: any;

const SUBMIT_PATH = "/videos";

// This helper will fire anytime the handlebar template can't find a value for the variable.
// In somecases, we will fill in handlebars on the server. So we output anything we don't know as 
// a handlebar template variable.
Handlebars.registerHelper('helperMissing', function (/* [args, ] options */) {
  var options = arguments[arguments.length - 1];
  return new Handlebars.SafeString(`{{${options.name}}}`)
});

Handlebars.registerHelper('toLowerCase', function (aString: string) {
  if (aString) {
    var result = new Handlebars.SafeString(aString.toLowerCase());
    return result;
  }
  return '';
});


const buildStyles = makeStyles((theme: any) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  form: {
    width: "100%",
  },
  gridContainer: {
    display: "grid",
    gridColumnGap: theme.spacing(1),
    gridRowGap: theme.spacing(0.5),
  },
  actionsContainer: {
    display: "grid",
    gridColumnGap: theme.spacing(1),
    gridRowGap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  renderedGrid: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`, //`
    border: `1px solid ${theme.palette.primary.main}`, //`
    borderRadius: 4,
    margin: `${theme.spacing(2)}px ${theme.spacing(4)}px`, //`
    // marginBottom: theme.spacing(2),
  },
  actionButton: {
    marginTop: theme.spacing(2),
    ...theme.styles.actionButton,
  },
  loading: {
    marginBottom: theme.spacing(2),
    marginTop: "-10%",
  },
  validationError: {
    margin: theme.spacing(2),
    textAlign: "center",
  },
  errorContainer: {
    "& .MuiGrid-container": {
      width: "100%",
    }
  }
}));

const createGridItemStyle = (gridConfig: any) => {
  if (!gridConfig) return {};
  //The grid does funny things when you don't provide all the area props
  const area = gridConfig.area.indexOf("/") === 0 ? `${gridConfig.area} / 1 / 1 / 1` : gridConfig.area;

  const style = {
    display: "grid",
    gridArea: area,
  } as CSSProperties;

  if (gridConfig.align) style.alignContent = gridConfig.align;
  if (gridConfig.justify) style.justifyContent = gridConfig.justify;

  return style;
}

interface IAppForm {
  appConfig: AppFormProps;
}

const AppForm: React.FC<IAppForm> = ({appConfig}) => {
  const classes = buildStyles();
  const { client, subdomain, blobUrl } = useCustomizations();
  const [isInitialized, setInitialized] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [isWorking, setWorking] = useState(false);
  const [theAppUrl, setAppUrl] = useState<null | string>(null);
  const [schema, setSchema] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<null | Record<string, string>>(null);
  const [injectedValues, setInjectedValues] = useState<null | Record<string, any>>(null);
  const [unused, setUnused] = useState<Record<string, any>>({});
  const [wasQueued, setWasQueued] = useState<boolean>(false);
  const [videoInfo, setVideoInfo] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [submitPath, setSubmitPath] = useState<string | null>(null);
  let token = useMemo(() => getSearchParam("token", true) ?? null, []);
  const apiKey = useMemo(() => getSearchParam("apiKey", true) ?? null, []);
  token = token ?? apiKey;
  const formikRef = useRef<FormikProps<Record<string, string>> | null>(null);

  //NOTE: For now, we're only paying attention to the first item in the "actions" array of the app.json.
  const formActions = useMemo(() => (appConfig?.actions && appConfig.actions.length > 0) ? [appConfig.actions[0]] : [DEFAULT_ACTION], [appConfig]);

  useEffect(() => {
    async function loadForm() {
      const defaults = await getQueryStringValues();
      const s = createSchema(appConfig.inputs);
      const i = createInitialValues(appConfig.inputs, defaults, blobUrl);

      const formValidation = validateForm(appConfig, i);
      setValidationError(formValidation);

      setSchema(s);
      setInitialValues(i);
      setInjectedValues(defaults);

      //Need to pass along any query string props that aren't used in the form as well
      // so capture any injected values that don't map to a field in the app schema,
      // and hold them for later.
      const uv = keys(defaults)?.reduce((output: Record<string, any>, key: string) => {
        const inp = appConfig.inputs.find(i => i.fieldId === key);
        if (!inp) output[key] = defaults[key];
        return output;
      }, {});

      setUnused(uv);
      setInitialized(true);
      console.log("baseUrl: ", getBaseUrl());
    }
    loadForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appConfig]);

  const submitFormWithAction = (action: AppActionProps, formikProps: FormikProps<Record<string, string>>) => () : Promise<any> => {
    setSubmitPath(action.submitPath ?? null);
    return formikProps.submitForm();
  }
  
  async function submitForm(values: any, actions: any) {
    //Check for a submit path on the AppActionProps, otherwise, use the default path
    const path = submitPath ?? SUBMIT_PATH;
    const createVideoUrl = `${getBaseUrl()}${path}`;
    
    console.log(`Posting to: ${createVideoUrl}`);

    //When we have a template field, this is redundant because we've already set the compiled values to the form
    const toSend = compileValues(appConfig?.inputs || [], values, client, subdomain, unused, true);
    console.log(toSend);
    setWorking(true);

    var response = null;
    if(token){
      response = await doFetchWithToken(createVideoUrl, "POST", toSend, token); // doFetchWithToken(fullUrl, "GET", null, user.token);    
    } else {
      response = await doFetch(createVideoUrl, "POST", toSend); // doFetchWithToken(fullUrl, "GET", null, user.token);    
    }
    
    console.log("Response: ", response);

    //Clear out the submit action path for next time.
    setSubmitPath(null);

    setWorking(false);
    if (response.isError) {
      actions.setStatus({
        isError: true,
        message: response.title ?? response.message,
      });
      setError(response.title ?? response.message);
      setErrorDetails(response);
    } else {
      setWasQueued(true);
      setVideoInfo(response);
      if(!appConfig?.isPolling) actions.resetForm();

      actions.setStatus(null); // Clear any previous errors if the submission is successful.
    }
  }

  function resetForm() {
    setInitialValues({});
    setAppUrl(null);
    setWorking(false);
  }

  function onRestart() {
    setWasQueued(false);
    setError(null);
    setErrorDetails(null);
    formikRef.current?.resetForm();
}

  if(error){
    return (
      <Grid container direction="column" alignItems="center" style={{ marginTop: "10%" }} className={classes.errorContainer}>
        <ErrorCard message={error} error={errorDetails}/>
        {isInitialized && <Button onClick={onRestart} size="large" color="secondary" variant="outlined" className={classes.actionButton}>Try Again</Button>}
      </Grid>
    );
  }

  else if (!isInitialized || !initialValues || !schema || !appConfig) {
    return (
      <Grid container justify="center">
        <LoadingIndicator message="Initializing app page..." />
      </Grid>
    );
  }

  else {
    return (
      <Grid id="form-container" container justify="center">
        {(appConfig.appTitle != null) && (
          <Grid item container justify="center">
            <Typography variant="h6" color="primary" className={classes.title}>{appConfig.appTitle}</Typography>
          </Grid>
        )}
        {isWorking &&
          <Grid item container className={classes.loading}>
            <LoadingIndicator message="Working..." />
          </Grid>
        }
        {(theAppUrl !== null) && (
          <Grid item container direction="column" justify="center" alignItems="center" className={classes.renderedGrid}>
            <Typography color="secondary">Render Complete: <Link target="_blank" href={theAppUrl}>Click Here to View your Video</Link></Typography>
            <Button onClick={resetForm} size="large" color="primary" variant="outlined" className={classes.actionButton}>Start Over</Button>
          </Grid>
        )}
        {wasQueued && appConfig.isPolling && 
          <AppWaitContainer videoId={videoInfo?.videoId} campaignId={videoInfo?.campaignId} videoUrl={videoInfo?.playerUrl} onRestart={onRestart}/>
        }
        {wasQueued && !appConfig.isPolling && 
          <SuccessCard message={appConfig.videoQueuedMessage || "Video queued successfully."} onRestart={onRestart} />
        }
        {!isWorking && !theAppUrl && !wasQueued &&
          <Grid item container>
            <Formik innerRef={formikRef} initialValues={initialValues} validationSchema={schema} onSubmit={submitForm}>
              {formikProps => (
                <Form className={classes.form}>
                  <AppFormWatcher formikProps={formikProps} injectedValues={injectedValues} allInputs={appConfig.inputs} unusedValues={unused}/>
                  <Grid id="form-grid" container direction="column" wrap="nowrap">

                    <div className={classes.gridContainer}>
                      {appConfig.inputs.map((inp: AppFieldProps) => {
                        return (
                          <div key={inp.fieldId} style={createGridItemStyle(inp.grid)}>
                            <FormikField fieldProps={inp as AppFieldProps} formikProps={formikProps} disabled={isWorking} options={(injectedValues ?? {})[inp.fieldId]} allInputs={appConfig.inputs} unusedValues={unused} />
                          </div>
                        )
                      })}
                    </div>

                    <div className={classes.actionsContainer}>
                      {formActions.map((act: AppActionProps) => {
                        return (
                          <div key={act.id} style={createGridItemStyle(act.grid)}>
                            <ActionButton actionProps={act} onClick={submitFormWithAction(act, formikProps)} isWorking={isWorking} isValid={formikProps.isValid} />
                          </div>
                        )
                      })}

                      {/* <div className={classes.actionsContainer}>
                        {formikProps.errors && <p>{JSON.stringify(formikProps.errors)}</p>}
                      </div> */}
                    </div>

                    <Grid item container direction="column" justify="center" alignItems="center">
                      {validationError &&
                        <Typography color="error" className={classes.validationError}>{validationError}</Typography>
                      }
                    </Grid>

                    <Grid item container justify="center">
                      {formikProps.status && formikProps.status.isError && (
                        <Typography style={{ color: 'red' }}>{formikProps.status.message}</Typography>
                      )}
                      {isWorking && formikProps.status && !formikProps.status.isError && (
                        <Typography style={{ color: '#ffb81c' }}>{formikProps.status.message}</Typography>
                      )}
                    </Grid>

                  </Grid>

                </Form>
              )}
            </Formik>

          </Grid>
        }
      </Grid>
    );
  }
};

export default AppForm;
