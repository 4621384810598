import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  card: {
    border: `1px solid ${theme.palette.success.dark}`, //`
    borderRadius: 5,
    background: `${theme.palette.success.light}33`,
    padding: theme.spacing(2),
  },
  contentText: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  details: {
    backgroundColor: `${theme.palette.success.light}11`, //` 
    width: "100%",
  },
  successLink: {
    fontSize: 20,
    textDecoration: "none",
    color: theme.palette.primary.main
  }
}));

interface ICardProps {
  message: string;
  link?: string;
  linkText?: string;
  onRestart?: () => void;
  restartText?: string;
};

const SuccessCard : React.FC<ICardProps> = ({message, link, linkText, onRestart, restartText, children}) => {
  const classes   = buildStyles();

  if(children){
    return (
      <Grid id="success-view" container justify="center" className={classes.root}>
        <Grid container className={classes.card}>
          {children}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid id="success-view" container justify="center" className={classes.root}>
      <Grid container className={classes.card}>
        <Grid container spacing={3}>
          <Grid item container justify="center">
            <Typography className={classes.contentText}>{message}</Typography>
          </Grid>
          {link && linkText &&
            <Grid item container justify="center">
              <a href={link} target="_blank" className={classes.successLink} rel="noreferrer">{linkText}</a>
            </Grid>
          }
          <Grid item container justify="center">
            <Button variant="contained" color="primary" onClick={onRestart}>{restartText ?? "OK"}</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SuccessCard;