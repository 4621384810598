import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { FormikFieldProps } from "app-types";

const buildStyles = makeStyles(theme => ({
  textField: {
    "& .MuiInput-root:before": {
      borderBottomColor: (theme as any).styles.page.color,
    },
    "& .MuiInput-root:hover:before": {
      borderBottomColor: (theme as any).styles.page.color,
    },
    "& .MuiInput-root": {
      color: (theme as any).styles.page.color,
    },
    "& .MuiInputLabel-root": {
      color: (theme as any).styles.page.color,
      fontSize: (theme as any).styles.page.fontSize || 15,
      fontWeight: (theme as any).styles.page.fontWeight || 'normal'
    },
    "& .MuiFormHelperText-root": {
      color: theme.palette.error.main,
    }
  },  
}));

const FormikTextField : React.FC<FormikFieldProps> = ({formikProps, fieldProps, disabled}) => {
  const classes = buildStyles();

  return (
    <TextField
      required={fieldProps.isRequired}
      disabled={fieldProps.isReadOnly || disabled}
      id={fieldProps.fieldId}
      label={fieldProps.fieldLabel}
      name={fieldProps.fieldName ?? fieldProps.fieldId}
      error={formikProps.touched[fieldProps.fieldId] && Boolean(formikProps.errors[fieldProps.fieldId])}
      helperText={formikProps.touched[fieldProps.fieldId] ? formikProps.errors[fieldProps.fieldId] : ""}
      onChange={formikProps.handleChange}
      onBlur={formikProps.handleBlur}
      value={formikProps.values[fieldProps.fieldId]}      
      className={classes.textField}
      fullWidth
      {...fieldProps.props}
      margin="dense"
    />
  )
}

export default FormikTextField;