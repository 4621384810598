import { makeStyles } from "@material-ui/core/styles";
import { FormControl, FormHelperText, InputLabel, Select, MenuItem } from "@material-ui/core";
import { FormikFieldProps, AppFieldOption } from "app-types";
import { useMemo } from "react";
import { isString } from "lodash";

const buildStyles = makeStyles(theme => ({
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,    
  },
  label: {
    color: (theme as any).styles.page.color,
    fontSize: 15,
  },
  helperText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(-0.5),
    marginBottom: theme.spacing(0.5),
  },
  select: {
    marginBottom: theme.spacing(1),
    "&.MuiInput-underline:before": {
      borderColor: `${(theme as any).styles.page.color} !important`,
    },
    "& .MuiSelect-root:hover:before": {
      borderBottomColor: (theme as any).styles.page.color,
    },
    "& .MuiSelect-root": {
      color: (theme as any).styles.page.color,
    },
    "& .MuiSelect-icon": {
      color: (theme as any).styles.page.color,
    },
    "& .MuiSelectLabel-root": {
      color: (theme as any).styles.page.color,
      fontSize: (theme as any).styles.page.fontSize || 15,
      fontWeight: (theme as any).styles.page.fontWeight || 'normal'
    }
  },
}));

interface Option {
  value: string;
  label: string;
}

const getOption = (opt: string | AppFieldOption) : Option => {
  if(isString(opt)){
    return { value: opt, label: opt };
  }
  else{
    return {value: opt.id, label: opt.label};
  }
}

const FormikSelectField : React.FC<FormikFieldProps> = ({formikProps, fieldProps, disabled}) => {
  const classes = buildStyles();
  const options = useMemo<Option[]>(() => fieldProps.options?.map(opt => getOption(opt)) ?? [], [fieldProps]);

  return (
    <FormControl className={classes.formControl}>
        <InputLabel className={classes.label}>{fieldProps.fieldLabel}</InputLabel>
        <Select
          required
          disabled={fieldProps.isReadOnly || disabled}
          id={fieldProps.fieldId}
          placeholder={fieldProps.fieldLabel}
          name={fieldProps.fieldName ?? fieldProps.fieldId}
          error={formikProps.touched[fieldProps.fieldId] && Boolean(formikProps.errors[fieldProps.fieldId])}
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
          value={formikProps.values[fieldProps.fieldId] || ""}      
          className={classes.select}
          fullWidth
          margin="dense"
        >
          {options.map(opt => <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>)}
        </Select>
        {formikProps.touched[fieldProps.fieldId] && <FormHelperText className={classes.helperText}>{formikProps.errors[fieldProps.fieldId]}</FormHelperText>}
    </FormControl>
  )
}

export default FormikSelectField;