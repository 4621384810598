import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ErrorCard, LoadingIndicator, useCustomizations } from "@personicom/customizations";
import AppForm from "./app-form";
import { AppFormProps, ApplicationsConfig } from "app-types";

const APP_CONFIG_FILE = "app.json";
const ALL_APPS_CONFIG_FILE = "applications.json";   //The configuration file that defines the applications in this subdomain

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(1),
  },
  errorContainer: {
    "& .MuiGrid-container": {
      width: "100%",
    }
  }  
}));

const AppView : React.FC = () => {
  const classes   = buildStyles();
  const { fetchFileAsJson } = useCustomizations();
  const { app } = useParams<any>();
  const [appConfig, setAppConfig] = useState<AppFormProps | null>(null);
  const [error, setError] = useState<string | null>(null);
  const isDefaultApp = useMemo(() => !app || app === "app", [app]);
  
  useEffect(() => {
    async function initialize() {
      //Check the path to see if this is the default app, or a custom one
      const appPath = isDefaultApp ? "" : `${app}/`;
      const configPath = `${appPath}${APP_CONFIG_FILE}`;
      let appForm = await fetchFileAsJson(configPath);

      if(appForm.isError){
        console.log("no app config file found for app: ", app, " and config file: ", configPath, ". Checking other options...");

        //Check to see if the app is located somewhere else
        const appsConfig = await fetchFileAsJson(ALL_APPS_CONFIG_FILE) as ApplicationsConfig;
        if(appsConfig && appsConfig.applications){
          
          //find the app config 
          // - if it's the default app, look for the one flagged as the default
          // - if the appConfig has a urlSlug, look for one that matches the :app from the url
          // - if the appConfig doesn't have a urlSlug, look for one where the id matches the :app from the url
          const myApp = appsConfig.applications.find(ac => (isDefaultApp && ac.isDefault) || (ac.urlSlug && ac.urlSlug === app) || (!ac.urlSlug && ac.id === app));
          if(myApp){
            //Use the configFileName, or fallback to app.json if there isn't one defined
            const myAppPath = `${myApp.configPath}/${myApp.configFileName ?? "app.json"}`;
            appForm = await fetchFileAsJson(myAppPath);
            
            if(!appForm || appForm.isError){
              console.log("no app config file found for app. Looking for:", myAppPath, myApp);
              //if still not found then we don't have one, let it fall through and the error below will catch it.
            }
          }
          else{
            console.error("this app could not be found in the applications.json file.", "isDefault:", isDefaultApp, "app: ", app, appsConfig);
          }
        }
        else{
          console.error("no applications.json file found. failed to find app config.");
        }
      }

      if(!appForm || !appForm.inputs){
        setError("There is no app declaration for the current domain & subdomain.");
        return;
      }

      setAppConfig(appForm as AppFormProps);
    }
    initialize();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefaultApp]);

  return (
    <Grid id="app-view" container justify="center" className={classes.root}>
      <Grid item md={6} sm={8} xs={12} container>
        {error && 
          <Grid container direction="column" alignItems="center" style={{ marginTop: "10%" }} className={classes.errorContainer}>
            <ErrorCard message={`This appears to be an invalid url. Please try again with a valid url.`} details={error.toString()} />
          </Grid>
        }

        {!appConfig && !error && 
          <Grid container justify="center">
            <LoadingIndicator message="Initializing..." />
          </Grid>
        }

        {appConfig && <AppForm appConfig={appConfig}/>}

      </Grid>
    </Grid>
  );
}

export default AppView;
