import React, { useCallback, useEffect, useRef } from "react";
import { isEqual, isString } from "lodash";
import { useCustomizations } from "@personicom/customizations";
import { TemplateDefaultProps, AppFieldProps } from "app-types";
import { compileValues } from "./app-helpers";
declare var Handlebars: any;

const AppFormWatcher : React.FC<TemplateDefaultProps> = ({ formikProps, injectedValues, allInputs, unusedValues }) => {

  const { blobUrl, client, subdomain } = useCustomizations();
  //Track old values to detect when there's a change, but need to mutate it so we don't trigger a re-render (and loop)
  const oldValues = useRef<Record<string, any>>({});

  const recalcValues = useCallback((inputs: AppFieldProps[], values: Record<string, any>) => {
    return compileValues(inputs, values, client, subdomain, unusedValues, false);    
  }, [client, subdomain, unusedValues]);

  useEffect(() => {
    let currValues = recalcValues(allInputs, formikProps.values);
    if (!isEqual(oldValues.current, currValues)) {

      const needHandlebars = allInputs.filter(inp => inp.default && isString(inp.default) && inp.default.includes('{{'));
      needHandlebars.forEach((fieldProps: AppFieldProps) => {
        let result:string = Handlebars.compile(fieldProps.default)(currValues);

        //HACK: Fix Me, make blobUrl a registered helper in handlebars.
        if (fieldProps.fieldType === 'image')
        {
          if(!result.toLowerCase().startsWith('http'))
          {
              result = blobUrl(result);
          }
        }

        if (fieldProps.fieldId !== undefined) {
          currValues = {
            ...currValues,
            [fieldProps.fieldId]: result
          };
        }  
      });

      oldValues.current = currValues;
      formikProps.setValues(currValues, true);
    }
  }, [formikProps, allInputs, recalcValues, blobUrl]);

  return null;
};

export default AppFormWatcher;