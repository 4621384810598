import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { ErrorCard, useCustomizations } from "@personicom/customizations";
import { useInterval } from "helpers/general-hooks";
import { doFetch, getBaseUrl } from "helpers/api-helpers";
import { SuccessCard } from "components";

const POLLING_INTERVAL = 3000;    //polling interval for how often to check the server for the video
const POLLING_PATH = "/video";

type PollingStatus = "error" | "polling" | "complete";
const PollingStatusMessage = {
  "error": "Polling Error",
  "polling": "Please Wait",
  "complete": "Render Complete",
};

const useStyles = makeStyles((theme) => ({
  inputWaitContainer: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  statusMessage: {
    marginTop: theme.spacing(1),
  },
  card: {
    marginTop: "10%",
    border: `1px solid ${theme.palette.primary.dark}`, //`
    borderRadius: 5,
    padding: theme.spacing(2),
    position: "relative",
  },
  cardBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    borderRadius: 5,
    background: theme.palette.primary.light,
    opacity: 0.2,
    pointerEvents : "none",
  },
  cardText: {
    fontSize: 18,
    fontWeight: 200,
    color: theme.palette.secondary.light,
    textAlign: "center",
  },
  videoLink: {
    marginTop: theme.spacing(1),
    // fontSize: 20,
    // fontWeight: 200,
    // color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(2),
    // borderRadius: 4,
    // background: theme.palette.primary.main,
    // padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`, //`
  },
  cardContent: {
    padding: theme.spacing(2),
  }
}));

type WaitContainerProps = {
  campaignId: string;
  videoId: string;
  videoUrl: string;
  onRestart: () => void;
}

const VodWaitContainer : React.FC<WaitContainerProps> = ({campaignId, videoId, videoUrl, onRestart}) => {
  const { client, subdomain } = useCustomizations();
  const classes = useStyles();
  // const { user } = useAuth();
  const [theRQI, setRQI] = useState<any>(null);
  const [pollStatus, setPollStatus] = useState<PollingStatus>("polling");
  const pollInterval = useMemo(() => { return (pollStatus === "polling" && subdomain && videoId) ? POLLING_INTERVAL : null; }, [pollStatus, subdomain, videoId]);
  
  const pollUrl = useMemo(() => {
    if(!campaignId || !videoId) return null;
    const baseUrl = getBaseUrl();
    const url = `${baseUrl}${POLLING_PATH}/${client}/${subdomain}/${campaignId}/${videoId}`;
    console.log("Polling url: ", url);
    return url;
  }, [client, subdomain, campaignId, videoId]);
  
  useInterval(async () => {
    if(!subdomain || !pollUrl){
      console.log("missing subdomain or videoId, skipping polling");
      return;
    } 

    const result = await doFetch(pollUrl, "GET", null);    //doFetchWithToken(url, "GET", null, user.token);
    if(result.isError){
      //deal with 404 errors, which means it's not a valid video url
      if(result.status){
        //New Pipeline takes a few seconds to queue an item into the Database. So you get 404's for a bit.
        if (result.status === 404)
        {
          console.log("404 waiting for video to queue. ", result);
          return;
        } else {
          setPollStatus("error");
          setRQI(result);
          console.log("Error checking for video: ", result);
          return;  
        }
      }
    }

    setRQI(result);

    if ((result !== null) && (result.isReady)) {
      setPollStatus("complete");
      console.log("Polling Complete." + result);
    }

    }, pollInterval);


  if(pollStatus === "polling"){
    return (
      <Grid container direction="column" alignItems="center" className={classes.inputWaitContainer}>
        <CircularProgress color="primary" />
        <Typography color="primary" className={classes.statusMessage}>{PollingStatusMessage[pollStatus]}</Typography>
      </Grid>
    );
  }
  else if(pollStatus === "error"){
    return (
      <Grid container direction="column" alignItems="center" className={classes.inputWaitContainer}>
        <ErrorCard message={`Video url is invalid. Technical support has been notified.`} details={theRQI?.message?.toString()} />
        {onRestart && <Button onClick={onRestart} color="primary">Start Over</Button>}
      </Grid>
    );
  }
  else {
    return (
        <Grid container direction="column" alignItems="center" className={classes.inputWaitContainer}>
          <Grid className={classes.card}> 
            <Grid className={classes.cardBackground}></Grid>                 
            <Grid container alignItems="center" justify="center" spacing={2} className={classes.cardContent}>
              <Grid item container justify="center">
                <Typography className={classes.cardText}>Congratulations, your video has been generated successfully!</Typography>
              </Grid>
              <Grid item container justify="center">
                <Button component="a" variant="contained" color="primary" size="large" href={videoUrl} target="_blank" className={classes.videoLink} rel="noreferrer">View your video</Button>
              </Grid>
              <Grid item container justify="center">
                <Button color="primary" variant="outlined" onClick={onRestart}>Start Over</Button>
              </Grid>
            </Grid>
          </Grid>
      </Grid>
    );
  }
}

export default VodWaitContainer;