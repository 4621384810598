import { filter, isString, keys } from "lodash";

const LOCAL_URL_BASE = "http://localhost:7071/api";
export const getBaseUrl = () => {
  const isLocalHost = Boolean(window.location.hostname.indexOf("localhost") >= 0); //needs to be >=, because 'localhost' matches' localhost' host name at pos 0
  if (isLocalHost) {
    return LOCAL_URL_BASE;
  }
  else {
    return "/api";
  }
}

//Gets the body fields that are blobs
const getBlobFields = (body: Record<string, any>) => {
  if(!body) return false;
  const imgFields = filter(keys(body), key => body[key] instanceof Blob);
  return imgFields;
}

//--
// Will prepare the body to be sent to the server
// This includes checking for images and using FormData if necessary.
// Otherwise, returns a stringified version of the body (if it's not already a string)
const prepareBody = (body: any) => {
  if(!body) return body;
  else if(isString(body)) return body;
  
  const blobFields = getBlobFields(body);  
  if(blobFields && blobFields.length > 0){
    //This body has one or more image files, so need to use formData
    let formData = new FormData();
    //enumerate the image fields, append them to the formData and remove them from the body object
    blobFields.forEach(id => {
      formData.append(id, body[id] || new Blob());
      delete body[id];
    });

    //add the remainder of the body as a payload
    formData.append("payload", JSON.stringify(body));
    return formData;
  }
  else return JSON.stringify(body);  
}

//TODO: standardize how the fetch responses are handled.
// export interface ApiResponse {
//   isError?: boolean;
//   status?: number;
//   message?: string;
//   errors?: Record<string, any>;     //TODO: make this more specific to what can be returned with standard errors
//   data?: any;
// };

//--
// performs a fetch, and gets a JSON result
export async function doFetch(url: string, method: string, body: any) {

  try {
    const bodyData = prepareBody(body);
    const result = await fetch(url, { method: method, body: bodyData });
    return await handleFetchResponse(result);    
  }
  catch (err : any) {
    return await handleFetchException(err);    
  }
}

//--
// performs a fetch with the provided bearer token, and gets a JSON result
export async function doFetchWithToken(url: string, method: string, body: any, token: string) {

  try {
    const headers = {
      "Authorization": "Bearer " + token
      ,"x-functions-key" : token //Used by Azure functions, same as ?code={token}
      ,"x-personicom-auth": "Bearer " + token //Used because SWA apps eat the Authorization header when submitting to Azure Functons.
    };


    const bodyData = prepareBody(body);
    const result = await fetch(url, { method: method, body: bodyData, headers });
    return await handleFetchResponse(result);
    
  }
  catch (err: any) {
    return await handleFetchException(err);    
  }
}

//=======
// Handles the response of a fetch request. Deals with errors and json data.
async function handleFetchResponse(result: Response){
  if(result.status < 200 || result.status >= 300){
    let errJson = null;
    try{
      errJson = await result.json();
    }
    catch(ex){}

    return {
      isError: true,
      status: result.status,
      message: result.statusText,
      ...errJson,
    };
  }
  else if (result.json) {
    const json = await result.json();

    if (!result.ok) {
      return {
        isError: true,
        status: result.status,
        message: result.statusText,
        ...json
      };
    }

    return json;  //all good, just return the result
  }
  else if (result.ok === true) {
    //Nothing to return, but all is good
    return {
      isError: false,
      message: "OK",
      ...result
    };
  }
}

//=========
// Handles an exception that occurs during a fetch request
async function handleFetchException(err: any) {
  let errMsg = "Unhandled exception during fetch. Our technical team has been notified.";
    if (err.text) errMsg = await err.text();

    return {
      isError: true,
      message: errMsg,
      status: 999,
    };
}