import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import AppHeader from "./app-header";
import AppBody from "./app-body";
import CustomizationsProvider, { CustomizationHelmet } from "@personicom/customizations";
import { getBaseUrl, ErrorCard, LoadingIndicator } from "@personicom/customizations";

const GA_TRACKER = "UA-102377011-8";
const GA_TRACKER_SECONDARY = "UA-157273197-1";    //Fallback, in case it's not found in the layout.json

const App: React.FC = () => {
  const [isReady, setReady] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [path, setPath] = useState("");

  useEffect(() => {
    async function initialize(){
      try{
        const basePath = await getBaseUrl();
        setPath(basePath);
        setReady(true);
      }
      catch(ex: any){
        // Set the error to be displayed
        setError(ex.message || ex.toString());
      }
    }

    initialize();
  }, []);

  if(error){
    return (
      <Grid container direction="column" alignItems="center" style={{marginTop: "10%"}}>
        <ErrorCard message={`An error occurred while loading the application.`} details={error} />
      </Grid>
    );
  }
  else if(!isReady){
    return <LoadingIndicator />;
  }
  else{
    return (
      <CustomizationsProvider>
        <CustomizationHelmet gaTrackerId={GA_TRACKER} gaTrackerSecondaryId={GA_TRACKER_SECONDARY} />
        <AppHeader />
        <AppBody />
      </CustomizationsProvider>
    );
  }
}

export default App;
